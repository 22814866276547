import { useEffect } from "react";

const CoC = () => {
  useEffect(() => {
    window.location.href =
      "https://technohack.notion.site/Code-Of-Conduct-7f4698ca44114a3596d08fa82fa55075";
  }, []);
  return <div></div>;
};

export default CoC;
